import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_menu = _resolveComponent("icon-menu")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "default-active": _ctx.routeList.customer,
    onSelect: _ctx.handleSelect,
    class: "the-menu"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu_item, {
        index: _ctx.routeList.customer
      }, {
        title: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("會員管理")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_icon_menu)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["index"])
    ]),
    _: 1
  }, 8, ["default-active", "onSelect"]))
}